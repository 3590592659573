import * as React from "react";
import { CharacterList } from "../api-documentation";

export const CharacterCodeReference = () => {
  return (
    <div>
      <h3>Character Code Reference</h3>
      <p>
        All characters that can be shown on a Vestaboard, including letters,
        digits, punctuation, and color chips, are encoded by an integer
        character code.
      </p>
      <CharacterList />
    </div>
  );
};