import { Alert } from "@material-ui/lab";
import * as React from "react";
import { Table } from "react-bootstrap";

const HELLO_WORLD_MESSAGE =
  "[[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,8,5,12,12,15,0,23,15,18,12,4,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]]";

export const LocalApi: React.FC = (props) => {
  return (
    <div>
      <h2>Local API</h2>
      <p>
        You can use your Vestaboard on a local network in parallel with our
        cloud services. If you would like to decouple your Vestaboard from our
        cloud, you can also set your firewall accordingly and only use the Local
        API. For eligibility, your Vestaboard must be paired, online and
        available to receive our latest firmware update. Future firmware updates
        will require connectivity to our cloud to complete as well.
      </p>
      <p>
        <Alert severity="warning">
          An IPv4 address is required for the Local API to be effective. Using
          an IPv6 address may result in inconsistent results when interacting
          with the Local API.
        </Alert>
      </p>

      <h4>Getting Started</h4>
      <p>
        Vestaboard owners can request access for a Local API enablement token{" "}
        <a href={"https://www.vestaboard.com/local-api"}>here</a>.
      </p>

      <h4>Enabling Local API</h4>
      <p>
        Following review of your submission, the owner of your Vestaboard will
        be emailed a token to enable the Local API and get your first key.
      </p>
      <div className="mt4">
        <Table bordered>
          <tbody>
            <tr>
              <td className="w-25">
                <strong>Method</strong>
              </td>
              <td>POST</td>
            </tr>
            <tr>
              <td>
                <strong>Path</strong>
              </td>
              <td>http://vestaboard.local:7000/local-api/enablement</td>
            </tr>
            <tr>
              <td>
                <strong>Headers</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Local-Api-Enablement-Token</code>
                      </td>
                      <td>
                        The Local API enablement token provided by Vestaboard
                        support
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample response</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      message: "Local API enabled",
                      apiKey:
                        "MDJEMEZEODEtRjNFMS00QUNFLUI0MzAtNjJCQkMyNUJDOUI5Cg",
                    })}
                  </code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>cURL Example</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    curl -X POST -H "X-Vestaboard-Local-Api-Enablement-Token:{" "}
                    {"<"}token{">"}"
                    http://vestaboard.local:7000/local-api/enablement
                  </code>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
        <h4>Sending a message via Local API</h4>
        <p>
          After following the steps above to enable the Local API, use the
          provided API Key to send messages locally.
        </p>
        <div className="mt4">
          <Table bordered>
            <tbody>
              <tr>
                <td className="w-25">
                  <strong>Method</strong>
                </td>
                <td>POST</td>
              </tr>
              <tr>
                <td>
                  <strong>Path</strong>
                </td>
                <td>http://vestaboard.local:7000/local-api/message</td>
              </tr>
              <tr>
                <td>
                  <strong>Headers</strong>
                </td>
                <td>
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th>Key</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <code>X-Vestaboard-Local-Api-Key</code>
                        </td>
                        <td>
                          The Local API token provided when you enabled the
                          Local API
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Sample request</strong>
                </td>
                <td>
                  <div className="bg-black green pa2">
                    <code>{HELLO_WORLD_MESSAGE}</code>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>cURL Example</strong>
                </td>
                <td>
                  <div className="bg-black green pa2">
                    <code>
                      curl -X POST -H "X-Vestaboard-Local-Api-Key: {"<"}key{">"}
                      " http://vestaboard.local:7000/local-api/message -d "
                      {HELLO_WORLD_MESSAGE}"
                    </code>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <h4>Reading the current message</h4>
          <p>
            You can also read the current message displayed on your Vestaboard.
          </p>
          <div className="mt4">
            <Table bordered>
              <tbody>
                <tr>
                  <td className="w-25">
                    <strong>Method</strong>
                  </td>
                  <td>GET</td>
                </tr>
                <tr>
                  <td>
                    <strong>Path</strong>
                  </td>
                  <td>http://vestaboard.local:7000/local-api/message</td>
                </tr>
                <tr>
                  <td>
                    <strong>Headers</strong>
                  </td>
                  <td>
                    <Table size="sm" bordered>
                      <thead>
                        <tr>
                          <th>Key</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <code>X-Vestaboard-Local-Api-Key</code>
                          </td>
                          <td>
                            The Local API key provided when you enabled the
                            Local API
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Sample response</strong>
                  </td>
                  <td>
                    <div className="bg-black green pa2">
                      <code>{HELLO_WORLD_MESSAGE}</code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>cURL Example</strong>
                  </td>
                  <td>
                    <div className="bg-black green pa2">
                      <code>
                        curl -H "X-Vestaboard-Local-Api-Key: your key"
                        http://vestaboard.local:7000/local-api/message
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
