import React from "react";
import "./App.css";
import { createBrowserHistory } from "history";
import { Router, Route } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import { DocumentationApp } from "./components/documentation-page/Main";
import { Theme } from "@vestaboard/installables";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <Theme>
      <Router history={history}>
        <Route path="/" component={DocumentationApp} />
      </Router>
    </Theme>
  );
};

export default App;
