import * as React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Redirect, Route, Switch } from "react-router";
import { documentationContents, DocumentationSidebar } from "./Sidebar";
import Logo from "../../logo.png";

const mobileNavBarStyle = {
  background: "#25282A",
  boxShadow: "0px 1px 7px grey",
};

const logoStyle = {
  height: "50px",
};

const sidebarStyle = {
  background: "#25282A",
  width: "300px",
  minWidth: "300px",
};

export const DocumentationApp: React.FC = (props) => {
  return (
    <div>
      <div className="db-m dn-l">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={mobileNavBarStyle}
        >
          <Navbar.Brand href="/">
            <img src={Logo} style={logoStyle} alt="Vestaboard" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {documentationContents.map((content) => (
                <Nav.Link href={`/${content.slug}`}>{content.title}</Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="flex flex-row">
        <div className="dn dib-l pl0 pr0" style={sidebarStyle}>
          <DocumentationSidebar />
        </div>
        <div
          style={{ flexGrow: 1 }}
          className="pa3 pa4-ns vh-100 overflow-scroll"
        >
          <Switch>
            {documentationContents.map((content) => (
              <Route path={`/${content.slug}`}>
                <div className="fadeIn">
                  <content.body />
                </div>
              </Route>
            ))}
            <Redirect exact from="/" to={documentationContents[0].slug} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
