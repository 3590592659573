import * as React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router";
import {
  AuthenticationSection,
  ConceptsSection,
  IntroductionSection,
  MessageReference,
} from ".";
import { CharacterCodeReference } from "./CharacterCodeReference";
import { LocalApi } from "./LocalApi";
import { ReadWriteApi } from "./ReadWriteApi";
import { history } from "../../App";
import Logo from "../../logo.png";
import { Button } from "@vestaboard/installables";

export const documentationContents = [
  {
    title: "Introduction",
    slug: "introduction",
    body: () => <IntroductionSection />,
  },
  {
    title: "Concepts",
    slug: "concepts",
    body: () => <ConceptsSection />,
  },
  {
    title: "Authentication",
    slug: "authentication",
    body: () => <AuthenticationSection />,
  },
  {
    title: "Methods Reference",
    slug: "methods",
    body: () => <MessageReference />,
  },
  {
    title: (
      <span>
        Local API&nbsp;&nbsp;&nbsp;
        <Badge pill variant="success">
          Beta
        </Badge>
      </span>
    ),
    slug: "local",
    body: () => <LocalApi />,
  },
  {
    title: (
      <span>
        Read / Write API&nbsp;&nbsp;&nbsp;
        <Badge pill variant="success">
          Updated
        </Badge>
      </span>
    ),
    slug: "read-write",
    body: () => <ReadWriteApi />,
  },
  {
    title: "Character Code Reference",
    slug: "characters",
    body: () => <CharacterCodeReference />,
  },
];

export const DocumentationSidebar: React.FC = (props) => {
  const location = useLocation();

  return (
    <div style={{ height: "100%" }} className="w-100 flex flex-column">
      <div className="pt3 ph3 tc center w-100 mb3">
        <a href="/">
          <img src={Logo} alt="Vestaboard" />
        </a>
        <span className="f6 white" style={{ marginTop: "-40px" }}>
          Documentation
        </span>
      </div>
      <div className="pa3 w-100">
        <ListGroup variant="flush" as="ul">
          {documentationContents.map((content) => (
            <ListGroup.Item
              variant="secondary"
              action
              active={location.pathname === `/${content.slug}`}
              onClick={() => history.push(`/${content.slug}`)}
            >
              {content.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className="pa3 tc">
        <Button
          buttonType="outline"
          onClick={() => (window.location.href = "https://web.vestaboard.com")}
        >
          Login to Vestaboard
        </Button>
      </div>
      <div style={{ flexGrow: 1 }}></div>
      <div className="white f7 flex-grow pa3">
        &copy; {new Date().getFullYear()} Vestaboard, Inc.
      </div>
    </div>
  );
};
