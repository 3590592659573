import * as React from "react";
import { Table } from "react-bootstrap";

const HELLO_WORLD_MESSAGE =
  "[[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,8,5,12,12,15,0,23,15,18,12,4,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]]";

export const ReadWriteApi: React.FC = (props) => {
  return (
    <div>
      <h2>Read / Write API</h2>
      <p>
        You can use the Read / Write API to read from and write to your
        Vestaboard.
      </p>
      <h4>Getting Started</h4>
      <p>
        Vestaboard users can enable your own Read / Write API key via the
        Settings section of the mobile app or from the Developer section of the
        web app. The key is specific to your Vestaboard, and only one key is
        allowed at a time. You can disable your API key or generate a new one at
        any time.
      </p>
      <h4>Reading the current message</h4>
      <p>
        Once you have a Read / Write API key, you can use it to get the current
        message.
      </p>
      <div className="mt4">
        <Table bordered>
          <tbody>
            <tr>
              <td className="w-25">
                <strong>Method</strong>
              </td>
              <td>GET</td>
            </tr>
            <tr>
              <td>
                <strong>Path</strong>
              </td>
              <td>https://rw.vestaboard.com/</td>
            </tr>
            <tr>
              <td>
                <strong>Headers</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Read-Write-Key</code>
                      </td>
                      <td>
                        The Read / Write key that you retrieved from web or
                        mobile app
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>Content-Type</code>
                      </td>
                      <td>application/json</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample response</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      currentMessage: {
                        layout: HELLO_WORLD_MESSAGE,
                        id: "172c7808-52db-4eb9-9ecf-505bd00436bc",
                      },
                    })}
                  </code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>cURL Example</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    curl -H "X-Vestaboard-Read-Write-Key: YOUR_API_KEY" -H
                    "Content-Type: application/json" https://rw.vestaboard.com/
                  </code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>JavaScript Example</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {`await fetch("https://rw.vestaboard.com/", {
    headers: {
      "Content-Type": "application/json",
      "X-Vestaboard-Read-Write-Key": "YOUR_API_KEY"
    },
    method: "GET"
  });`}
                  </code>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h4>Writing a new message</h4>
      <p>
        With your Read / Write API key, you can also send a new message. Please
        note that the Read / Write API does not accept blank messages. Also note
        that if you send more than 1 message every 15 seconds, you are likely to
        have messages dropped as we rate-limit the volume of messages sent to
        the board.
      </p>
      <div className="mt4">
        <Table bordered>
          <tbody>
            <tr>
              <td className="w-25">
                <strong>Method</strong>
              </td>
              <td>POST</td>
            </tr>
            <tr>
              <td>
                <strong>Path</strong>
              </td>
              <td>https://rw.vestaboard.com</td>
            </tr>
            <tr>
              <td>
                <strong>Headers</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Read-Write-Key</code>
                      </td>
                      <td>
                        The Read / Write key that you retrieved from web or
                        mobile app
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>Content-Type</code>
                      </td>
                      <td>application/json</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample requests</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>{HELLO_WORLD_MESSAGE}</code>
                </div>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>{`{text: "Hello World"}`}</code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample response</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      status: "ok",
                      created: 1687290396895,
                      id: "a8a12384-f26d-496c-89ef-bf7e92768ab7", // generated
                    })}
                  </code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>cURL Examples</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    curl -X POST -H "X-Vestaboard-Read-Write-Key: YOUR_API_KEY"
                    -H "Content-Type: application/json" -d "
                    {HELLO_WORLD_MESSAGE}" https://rw.vestaboard.com/
                  </code>
                </div>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {`curl -X POST -H "X-Vestaboard-Read-Write-Key: YOUR_API_KEY" -H "Content-Type: application/json" -d "{\\"text\\": \\"Hello World\\"}" https://rw.vestaboard.com/`}
                  </code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>JavaScript Examples</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {`await fetch("https://rw.vestaboard.com/", {
    body: JSON.stringify([
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 8, 5, 12, 12, 15, 0, 23, 15, 18, 12, 4, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ]),
    headers: {
      "Content-Type": "application/json",
      "X-Vestaboard-Read-Write-Key":  "YOUR_API_KEY"
    },
    method: "POST"
  });`}
                  </code>
                </div>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {`await fetch("https://rw.vestaboard.com/", {
    body: JSON.stringify({text: "Hello World"}),
    headers: {
      "Content-Type": "application/json",
      "X-Vestaboard-Read-Write-Key":  "YOUR_API_KEY"
    },
    method: "POST"
  });`}
                  </code>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
