import React from "react";
import { Row, Col, Button, Table, Badge, Jumbotron } from "react-bootstrap";
import { Simulator } from "../simulator";
type Message = number[][];

interface IMessageSampleProps {
  characters: Message;
}

type MessageSampleProps = IMessageSampleProps;

export const MessageSample: React.FC<MessageSampleProps> = (props) => {
  return (
    <div>
      <Row>
        <Col sm={6}>
          <code>
            <p>[</p>
            {props.characters.map((row) => (
              <p>[{row.join(", ")}]</p>
            ))}
            ]
          </code>
        </Col>
        <Col sm={6}>
          <Simulator message={props.characters} width={600} />
        </Col>
      </Row>
    </div>
  );
};

export const Tab: React.FC = () => <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

export const ViewerEndpointDocumentation = () => {
  return (
    <div>
      <Table bordered>
        <tbody>
          <tr>
            <td className="w-25">
              <strong>Method</strong>
            </td>
            <td>GET</td>
          </tr>
          <tr>
            <td>
              <strong>Path</strong>
            </td>
            <td>/viewer</td>
          </tr>
          <tr>
            <td>
              <strong>Headers</strong>
            </td>
            <td>
              <Table size="sm" bordered>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>X-Vestaboard-Api-Key</code>
                    </td>
                    <td>
                      The key half of the API Credential that was created.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>X-Vestaboard-Api-Secret</code>
                    </td>
                    <td>
                      The secret half of the API Credential that was created.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Sample request</strong>
            </td>
            <td>
              <div className="bg-black green pa2">
                <code>curl \</code>
                <br></br>
                <Tab />
                <code>-H "X-Vestaboard-Api-Key: df291a0b</code>
                <ColorPlaceholder />
                <code>" \</code>
                <br></br>
                <Tab />
                <code>-H "X-Vestaboard-Api-Secret: 8a100f55</code>
                <ColorPlaceholder />
                <code>" \</code>
                <br></br>
                <Tab />
                <code>https://platform.vestaboard.com/viewer</code>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Sample response</strong>
            </td>
            <td>
              <div className="bg-black green pa2">
                <code>
                  {JSON.stringify({
                    type: "installation",
                    _id: "f35032ce-fd2b-4afb-8a24-d71c8085303c",
                    _created: "1577829607582",
                    installation: {
                      _id: "562038d1-6fdb-4ba5-ae8b-bc9ec882178d",
                    },
                  })}
                </code>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export const AuthenticationSection = () => {
  return (
    <div>
      <h3>Authentication</h3>
      <p>
        Access to the Vestaboard Platform is performed by providing an API key
        and secret, collectively known as an API Credential, in the headers of
        an HTTP request.
      </p>
      <p>
        Each API Credential is scoped to an entity within the Vestaboard
        Platform. Currently, all API Credentials are issued to Installation
        objects, and access to other resources and actions is scoped as such.
      </p>
      <p>
        The <code>/viewer</code> endpoint provides information about the current
        holder of the API Credential that was provided in the HTTP request
        header.
      </p>
      <div className="w-85-ns">
        <ViewerEndpointDocumentation />
      </div>
    </div>
  );
};

export const ConceptsSection = () => {
  return (
    <div>
      <h3>Platform Concepts</h3>
      <p>
        The Vestaboard Platform is built for existing and future use cases that
        we have on our product roadmap, and provides multiple levels of
        extensibility for third-party developers to begin building integrations.
      </p>
      <div className="mt4">
        <h4>Concepts</h4>
        <Table>
          <tbody>
            <tr>
              <td className="w-20">
                <strong>Viewer</strong>
              </td>
              <td>
                <p>
                  The Viewer is the current authenticated entity against the
                  Vestaboard API. It's the object that is providing API
                  credentials (key and secret) with the request. Currently, all
                  API Credentials are assigned to an Installation that is
                  automatically created upon API Credential creation. (See the
                  Authentication section for more details.)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Account</strong>
              </td>
              <td>
                An Account represents an email address and password pair that
                can login to Vestaboard and perform actions.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Tenant</strong>
              </td>
              <td>
                <p>
                  A Tenant is any entity that uses Vestaboard. By default, all
                  Accounts are single-account Tenants. In the future, Tenants
                  can be created for companies and other organizations that
                  co-manage Vestaboards.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Installable</strong>
              </td>
              <td>
                <p>
                  The concept of an Installable captures various levels of
                  integration with the Vestaboard Platform. Examples of
                  Installables might include:
                </p>
                <ul>
                  <li>A Slack app</li>
                  <li>
                    A curated, auto-rotating set of Ernest Hemingway quotes
                  </li>
                  <li>A user-controllable set of recurring messages</li>
                  <li>
                    An app that displays the current weather in a postal code
                  </li>
                  <li>A single, one-time use Mother's Day 2020 message</li>
                </ul>
                <p>
                  You can think of an Installable as any software mechanism that
                  automatically publishes content to a Vestaboard, regardless of
                  the complexity of the integration and regardless of the
                  lifetime or source of the messages.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Installation</strong>
              </td>
              <td>
                <p>
                  An Installable is installed to a Tenant, creating an
                  Installation, and then the Installation is ready for use for
                  all Accounts with access to that Tenant.
                </p>
                <p>
                  In some cases, an Installable may require tenant-wide
                  information to create an Installation, for example,
                  authentication with a third-party site like Slack, or might
                  ask a user what refresh frequency they would like for all uses
                  of that Installable in their account. In other cases, an
                  Installation can be created automatically when the first
                  Subscription is created for the Installable.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Subscription</strong>
              </td>
              <td>
                <p>
                  A user can allow an Installation to post messages to a Board
                  by creating a Subscription between that Installation and
                  Board. The Installation can then discover the new Subscription
                  and begin posting to it.
                </p>
                <p>
                  Subscriptions always require reference to an Installation,
                  however for Installables without Tenant-level detail (see the
                  Installation section above), an Installation may be created
                  automatically when the first Subscription for an Installable
                  is created in a Tenant.
                </p>
                <p>
                  Subscriptions may also have subscription-level settings, such
                  as the Slack channel with which to integrate, or a postal code
                  for which to show weather information.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Message</strong>
              </td>
              <td>
                <p>
                  A Message is a set of characters to display on a Board. The
                  message may either be of natural text, or a set of Vestaboard
                  Characters to explicitly show in each position on a Board.
                </p>
                <p>
                  All Messages are posted to a Subscription, whether created
                  manually (in the mobile or web apps) or via an Installable. In
                  the case of manually-created messages, a default Subscription
                  that is created for the Board is used.
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Board</strong>
              </td>
              <td>
                <p>
                  A Board is a logical device that displays Messages. Boards
                  have a history of Messages and a set of Subscriptions that
                  determine what content is shown on the Board.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Device</strong>
              </td>
              <td>
                <p>
                  Every endpoint that can display Vestaboard content is a
                  Device. This includes the virtual Vestaboard simulator you see
                  in the web and mobile apps, as well as the hardware Vestaboard
                  that you can preorder.
                </p>
                <p>
                  Multiple Devices may map to a single Board. In the case of a
                  Vestaboard Simulator in-browser, a mobile app previewing a
                  Vestaboard's current message, and the Vestaboard itself, the
                  three aforementioned Devices are associated with a single
                  Board.
                </p>
                <p>
                  A hardware Vestaboard (itself a Device) may be assigned and
                  re-assigned to different Boards throughout its lifetime. Other
                  Devices are ephemeral and exist only for the lifetime of the
                  use of that Device, e.g., the web browser simulation of
                  Vestaboard (once the web browser is closed, the device no
                  longer exists and is disassociated.)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Format</strong>
                <br />
                <Badge pill variant="warning">
                  Coming Soon
                </Badge>
              </td>
              <td>
                <p>
                  A Format is a pre-defined set of layout rules that can be
                  applied to a message. Currently, the only Format is "default"
                  which applies centering when appropriate, truncates text when
                  the message is longer than supported by the Device, and wraps
                  lines when lines exceed the column count.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Subscription Set</strong>
                <br />
                <Badge pill variant="warning">
                  Coming Soon
                </Badge>
              </td>
              <td>
                <p>
                  A Subscription Set aggregates multiple Subscriptions and
                  specifies rules for those Subscriptions in the context of the
                  whole set. For example, a Subscription Set may specify a
                  certain order of its member Subscriptions which determines
                  priority for display on Vestaboard when multiple Subscriptions
                  are receiving messages simultaneously. This concept is not yet
                  in the API or user interface.
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt4">
        <h4>Automatic creation of objects</h4>
        <p>
          As mentioned above, some Vestaboard concepts need not be created
          explicitly for certain use cases. For example:
        </p>
        <ul>
          <li>
            A Tenant is created for every Account to reduce onboarding friction.
            In the future, a new Tenant can be created for organizations that
            contain multiple accounts. This concept is not currently exposed in
            the UI and is not core to the current set of API use cases.
          </li>
          <li>
            Every Board has a default Subscription not tied to an Installation
            that is used for the purposes of receiving messages from the web and
            mobile interfaces.
          </li>
          <li>
            An relatively simple Installable, e.g. one that creates a one-time
            use Message, does not require explicit installation of a that
            Installable for a subscription to be created; this Installation will
            be created in the background before the Subscription is created.
          </li>
        </ul>
        <p>
          Additionally, The Vestaboard Platform will automatically create an
          Installable, Installation, and Subscription for each Board in a Tenant
          upon creation of a new API Credential. This creates the scaffolding to
          begin sending messages to Vestaboard immediately without needing to
          expose these concepts to the end user or to developers until
          necessary.
        </p>
      </div>
    </div>
  );
};

export const IntroductionSection = () => {
  return (
    <div>
      <h3>Introduction</h3>
      <p>
        Welcome to the Vestaboard Platform documentation. This site will
        introduce the API's concepts, message structure, and additional details
        for you to begin building for Vestaboard.
      </p>
      <Jumbotron>
        <div className="center tc">
          <div>
            <strong>
              Create your Vestaboard account to begin using the Vestaboard
              Platform.
            </strong>
          </div>
          <div className="mt3">
            <Button
              variant="primary"
              onClick={() =>
                (window.location.href = "https://web.vestaboard.com")
              }
            >
              Vestaboard Web App
            </Button>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
};

const CHIP_SIZE = 10;

const colorChipStyle: React.CSSProperties = {
  width: CHIP_SIZE,
  height: CHIP_SIZE,
  color: "white",
  textAlign: "center",
  display: "inline-block",
  minWidth: CHIP_SIZE * 8,
  minHeight: CHIP_SIZE,
};

export const ColorPlaceholder: React.FC = (props) => (
  <div style={{ ...colorChipStyle, background: "#fff" }}></div>
);

export const MessageReference = () => {
  return (
    <div>
      <h3>Methods Reference</h3>
      <p>
        This page documents the API methods available to developers on the
        Vestaboard Platform.
      </p>
      <div className="mt4">
        <h4>Get Viewer</h4>
        <p>
          This method describes the current Viewer. (See Authentication for more
          details.)
        </p>
        <ViewerEndpointDocumentation />
      </div>
      <div className="mt4">
        <h4>List all Subscriptions</h4>
        <p>
          This endpoint lists all Subscriptions to which the Viewer
          authenticated via the API key and secret has access.
        </p>
        <Table bordered>
          <tbody>
            <tr>
              <td className="w-25">
                <strong>Method</strong>
              </td>
              <td>GET</td>
            </tr>
            <tr>
              <td>
                <strong>Path</strong>
              </td>
              <td>/subscriptions</td>
            </tr>
            <tr>
              <td>
                <strong>Headers</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Api-Key</code>
                      </td>
                      <td>
                        The key half of the API Credential that was created.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Api-Secret</code>
                      </td>
                      <td>
                        The secret half of the API Credential that was created.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample request</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>curl \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Key: df291a0b</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Secret: 8a100f55</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>https://platform.vestaboard.com/subscriptions</code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample response</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      subscriptions: [
                        {
                          _id: "bc6412b3-3aa6-4684-8962-cd2876942c0d",
                          _created: "1577833654294",
                          installation: {
                            _id: "fefb8778-9500-4522-b115-29c0dd13c402",
                            installable: {
                              _id: "b629d7df-7ecb-4460-b51e-f87fa942f113",
                            },
                          },
                          boards: [
                            {
                              _id: "a1e866f2-e77e-45d8-997a-2e3ebeff4961",
                            },
                          ],
                        },
                      ],
                    })}
                  </code>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt4">
        <h4>Create a new Message</h4>
        <p>
          This endpoint allows for creation of a new Message to a Subscription
          for which the Viewer has access.
        </p>
        <Table bordered>
          <tbody>
            <tr>
              <td className="w-25">
                <strong>Method</strong>
              </td>
              <td>POST</td>
            </tr>
            <tr>
              <td>
                <strong>Path</strong>
              </td>
              <td>/subscriptions</td>
            </tr>
            <tr>
              <td>
                <strong>Headers</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-30">
                        <code>X-Vestaboard-Api-Key</code>
                      </td>
                      <td>
                        The key half of the API Credential that was created.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>X-Vestaboard-Api-Secret</code>
                      </td>
                      <td>
                        The secret half of the API Credential that was created.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path Parameters</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Parameter</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-30">
                        <code>Subscription ID</code>
                      </td>
                      <td>The ID of the Subscription to which to post</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>POST Body</strong>
              </td>
              <td>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Value</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-30">
                        <code>text</code>
                      </td>
                      <td>
                        The message to send to the specified Subscription. If
                        text is specified, lines will be centered horizontally
                        and vertically if possible. Character codes will be
                        inferred for alphanumeric and punctuation, or can be
                        explicitly specified in-line in the message with curly
                        braces containing the character code.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
                <tr>
                  <Table size="sm" bordered>
                    <p>or</p>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="w-30">
                          <code>characters</code>
                        </td>
                        <td>
                          A 6x22 two-dimensional array of character codes to
                          send to the specified Subscription. If characters are
                          specified, the characters will be shown in the exact
                          position in the array.
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </tr>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample requests</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>curl \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Key: df291a0b</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Secret: 8a100f55</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>
                    -d '{"{"}"text": "<ColorPlaceholder />"{"}"}'
                  </code>
                  <br />
                  <Tab />
                  <code>https://platform.vestaboard.com/subscriptions/</code>
                  <ColorPlaceholder />
                  <code>/message</code>
                </div>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>curl \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Key: df291a0b</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>-H "X-Vestaboard-Api-Secret: 8a100f55</code>
                  <ColorPlaceholder />
                  <code>" \</code>
                  <br></br>
                  <Tab />
                  <code>
                    -d "{"{"}"characters":
                    [[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]]
                    {"}"}"
                  </code>
                  <br />
                  <Tab />
                  <code>https://platform.vestaboard.com/subscriptions/</code>
                  <ColorPlaceholder />
                  <code>/message</code>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Sample response</strong>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      message: {
                        id: "1125e36d-4e3a-40fb-a87b-1aa90f0997a1",
                        text: "Test",
                        created: "1577839720478",
                      },
                    })}
                  </code>
                </div>
              </td>
              <td>
                <div className="bg-black green pa2">
                  <code>
                    {JSON.stringify({
                      message: {
                        id: "1125e36d-4e3a-40fb-a87b-1aa90f0997a1",
                        created: "1577839720478",
                      },
                    })}
                  </code>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
