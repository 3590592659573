import * as React from "react";
import { Table, Spinner } from "react-bootstrap";
import { useCharacterList, ICharacter } from "../character-list";
const VESTABOARD_BLACK = "#25282A";
const CHIP_SIZE = "30px";

const colorChipStyle: React.CSSProperties = {
  width: CHIP_SIZE,
  height: CHIP_SIZE,
  color: "white",
  textAlign: "center",
  fontSize: "12pt",
  lineHeight: CHIP_SIZE,
};

const chunk = (array: any[], size: number) =>
  Array.from({ length: Math.ceil(array.length / size) }, (value, index) => {
    return array.slice(index * size, index * size + size);
  });

interface ICharacterTableProps {
  characters: ICharacter[];
}

type CharacterTableProps = ICharacterTableProps;

const CharacterTable: React.FC<CharacterTableProps> = (props) => {
  return (
    <Table bordered striped size="sm">
      <thead>
        <tr>
          <th className="w-33">Preview</th>
          <th className="w-33">Name</th>
          <th className="w-33">Code</th>
        </tr>
      </thead>
      <tbody>
        {props.characters.map((character) => (
          <tr>
            <td>
              {character.name === "Blank" ? (
                <div />
              ) : character.naturalValue ? (
                <div
                  style={{ ...colorChipStyle, background: VESTABOARD_BLACK }}
                >
                  {character.naturalValue}
                </div>
              ) : character.color ? (
                character.name === "White" ? (
                  <div
                    style={{
                      ...colorChipStyle,
                      background: character.color,
                      borderWidth: 1,
                      borderColor: "#CCC",
                      borderStyle: "solid",
                    }}
                  ></div>
                ) : character.name === "Filled" ? (
                  <div
                    style={{
                      ...colorChipStyle,
                      background: "#FFF",
                      borderWidth: 1,
                      borderColor: "#CCC",
                      borderStyle: "solid",
                    }}
                  ></div>
                ) : (
                  <div
                    style={{ ...colorChipStyle, background: character.color }}
                  ></div>
                )
              ) : null}
            </td>
            <td>
              <i>{character.name}</i>
            </td>
            <td>
              <code>{character.integerValue}</code>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const CharacterList: React.FC = () => {
  const { characters, loading } = useCharacterList();

  return (
    <div>
      {loading && <Spinner animation="border"></Spinner>}
      {!loading && characters && (
        <>
          <div className="dn db-l">
            <div className="flex flex-row">
              {chunk(characters, 23).map((chunk, index) => (
                <div className="w-50 pr4">
                  <CharacterTable characters={chunk} />
                </div>
              ))}
            </div>
          </div>
          <div className="dn db-m dn-l">
            <div className="flex flex-row">
              {chunk(characters, 35).map((chunk, index) => (
                <div className="w-50 pr4">
                  <CharacterTable characters={chunk} />
                </div>
              ))}
            </div>
          </div>
          <div className="dn-ns">
            <div className="flex flex-row">
              {chunk(characters, 80).map((chunk, index) => (
                <div className="w-50 pr4">
                  <CharacterTable characters={chunk} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ApiDocumentation: React.FC = () => {
  return (
    <div>
      <div className="mt4"></div>
      <div className="mt4">
        <h3>Automatic Formatting</h3>
        <p>
          Messages can be sent in text format to be automatically formatted if
          the specific positioning of characters does not require precise
          control.
        </p>
      </div>
    </div>
  );
};
