import * as React from "react";
import Axios from "axios";
import { API_BASE_URL } from "../../App";

export interface ICharacter {
  naturalValue: string | null;
  integerValue: number;
  name: string;
  description: string | null;
  color: string | null;
}

export type Character = ICharacter;

export const useCharacterList = () => {
  const [loading, setLoading] = React.useState(false);
  const [characters, setCharacters] = React.useState<Character[]>([]);

  const fetchCharacters = async () => {
    setLoading(true);
    const response = await Axios.get(
      `${API_BASE_URL}/documentation/characters`
    );
    setCharacters(response.data.characters);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCharacters();
  }, []);

  return {
    characters,
    loading,
  };
};
